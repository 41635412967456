<template>
  <PageLoader v-if="pageLoading" :loading="pageLoading" />

  <div v-else class="container_margin">
    <div class="row">
      <div class="col-12 col-md-2"></div>
      <div class="col-12 col-md-8">
        <button
          v-if="full_content.type === 'html'"
          class="btn btn-primary float-right mt-3"
          @click="ExportData"
        >
          Export to PDF
        </button>
        
        <h3 :class="['text-blue mt-4',
          {'text-center': full_content.type != 'html'},
        ]">
          <b>{{ "Full Case" }}</b>
        </h3>

        <div class="card shadow-sm p-3 mt-2 key_Card">
          <p
            v-if="full_content.type === 'html'"
            class="m-2 border-bottom mb-3"
            v-html="sanitizedHTML"
          ></p>
          <iframe
            v-else-if="full_content.type === 'pdf'"
            class="m-2 border-bottom mb-3"
            :src="full_content.value"
            height="566px"
            width="100%"
            frameborder="0"
          ></iframe>
          
          <p class="font-10 text-muted">
            NexLaw does not hold any copyright for the case content. The
            content within this material, including but not limited to text,
            images, graphics, logos, audio clips, and software, belongs to
            Comonlii and is protected by copyright laws. All rights are
            reserved by Comonlii
            <em>
              (CommonLII is coordinated by AustLII, a joint facility of UTS
              and UNSW Faculties of Law, in cooperation with some of the
              members of the Free Access to Law Movement (FALM)). For
              Malaysian Cases, the copyright solely belongs to Malaysian
              Judiciary (e-court kehakiman).
            </em>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import CaseSearch from "@/store/CaseSearch.js";
import { sanitizeHTML, ExportDataPDF } from "../../store/utils";

export default {
  components: {
    PageLoader,
  },
  data() {
    return {
      pageLoading: true,
      full_content: [],
    };
  },

  computed: {
    sanitizedHTML() {
      return sanitizeHTML(this.full_content.value);
    },
  },

  methods: {
    ExportData() {
      ExportDataPDF(
        sanitizeHTML(this.full_content.value)
      );
      this.$toast.success('Successfully downloaded PDF');
    },
  },

  created() {
    CaseSearch.OpenSummary(this.$route.params.jurisId)
      .then((response) => {
        const Content = response.data.data.precedents
          .find((item) =>
            item.id == this.$route.params.id
          );

        this.full_content = Content ? Content.full_content : [];
        this.pageLoading = false;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.container_margin {
  padding: 1rem 2rem;
}

.key_Card {
  max-height: 726px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
